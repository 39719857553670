import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import SupplierDrawerStore from '../Drawer/SupplierDrawerStore';
import suppliersTableStore from '../Table/SuppliersTableStore';
import { Supplier } from '../Types/Types';
import SuppliersTable from "../Table/SuppliersTable";
import SupplierDrawer from "../Drawer/SupplierDrawer";

const supplierDashboardDrawerStore = new SupplierDrawerStore();

const SupplierDashboard: React.FC = observer(() => {
  const {
    drawerOpen,
    drawerMode,
    supplier,
    openCreateDrawer,
    closeDrawer,
    setShowValidation,
    isFormValid,
  } = supplierDashboardDrawerStore;
  const { saveSupplier } = suppliersTableStore;

  const [savingInProgress, setSavingInProgress] = useState(false);
  const { t } = useTranslation();

  const handleSave = async (supplierUpdate: Supplier) => {
    setSavingInProgress(true);
    setShowValidation(true);
    if (isFormValid) {
      await saveSupplier(supplierUpdate);
      closeDrawer();
    }
    setSavingInProgress(false);
  };

  const handleOpenCreateDrawer = () => {
    setSavingInProgress(false);
    openCreateDrawer();
  };

  return (
        <div>
            <Box sx={{ marginLeft: 1 }}>
                <h1>{t('suppliers')}</h1>
            </Box>
            <Button onClick={handleOpenCreateDrawer} data-testid="addNewSupplierButton">{t('addNewSupplier')}</Button>
            <Box sx={{ marginTop: 1 }}>
                <SuppliersTable/>
            </Box>

            <SupplierDrawer
                isOpen={drawerOpen}
                onClose={closeDrawer}
                mode={drawerMode}
                initialSupplier={supplier}
                onSave={handleSave}
                loading={savingInProgress}
                store={supplierDashboardDrawerStore}
            />
        </div>
  );
});

export default SupplierDashboard;
