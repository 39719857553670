import { action, makeObservable, observable, toJS } from 'mobx';
import { getFirestore } from 'firebase/firestore';
import * as Types from '../Types/Types';
import { PaginationDirection } from '../../Common/Types';
import { PageableCollectionStoreConfig } from '../../Common/Service/PageableCollectionStore';
import BasicPageableCollectionStore from '../../Common/Service/BasicPageableCollectionStore';

const suppliersPageableCollectionStoreConfig: PageableCollectionStoreConfig<Types.Supplier> = {
  collectionPath: 'projects/101/suppliers',
  orderByField: 'name',
  orderByDirection: 'asc',
  pageSize: 50,
  convertDocToEntity: (documentData) => ({
    ...documentData.data(),
    id: documentData.id,
  }),
};

class SuppliersTableStore extends BasicPageableCollectionStore<Types.Supplier> {

  suppliersSearchCriteria: Types.SupplierSearchCriteria = {};

  constructor() {
    super(suppliersPageableCollectionStoreConfig)
    makeObservable(this, {
      suppliersSearchCriteria: observable,
      saveSupplier: action,
      deleteSupplier: action,
    });
  }

  fetchSuppliers = async (direction: PaginationDirection) => {
    const db = getFirestore();
    return super.fetchEntities(db, direction);
  };

  saveSupplier = async (supplier: Types.Supplier) => {
    await super.saveEntity(toJS(supplier));
  };

  async deleteSupplier(id: string) {
    await super.deleteEntity(id);
  }
}

const suppliersTableStore = new SuppliersTableStore();
export default suppliersTableStore;